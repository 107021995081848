import React from 'react';

import { GatsbyImage, getImage } from "gatsby-plugin-image"

import { floatImage } from './coverLayout.module.scss'

const CoverLayout = ({coverImage, children})=> {
  const image = getImage(coverImage.image)

    return (
      <>
        <GatsbyImage
          image={image}
          className={floatImage}
          alt={coverImage.alt}
        />
        {children}
        <hr />
      </>
    )
}

export default CoverLayout;