import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import CoverLayout from "../components/CoverLayout"
import { HeadComponent } from "../components/HeadComponent"
import LogosReferences from "../components/LogosReferences"

import { main } from './index.module.scss'

const IndexPage = ({ data }) => {

  const coverImage = {
    image: data.image,
    alt: "© - Milomon - https://www.instagram.com/milomon/"
  }

  return (
    <Layout>
      <HeadComponent title="Urbanisme & Cartographie" />
        <CoverLayout coverImage={coverImage}>
          <div className={main}>
            <p>
              KADATA est un bureau d'étude et de conseil spécialisé sur les
              questions foncières, le traitement de données, et les solutions de
              cartographie collaborative.
            </p>
            <p>
              Kadata est éditeur de la solution{" "}
              <a
                href="https://spreadsheep.io"
                target="_blank"
                rel="noopener noreferrer"
              >
                Spreadsheep
              </a>
              .
            </p>
          </div>
        </CoverLayout>
      <LogosReferences />
    </Layout>
  )}

export const pageQuery = graphql`{
  image: file(relativePath: {eq: "milomon/BFXEoptOZ3p.jpg"}) {
    childImageSharp {
      gatsbyImageData(
        width: 480
        tracedSVGOptions: {background: "#fff", color: "#007FE6"}
        placeholder: TRACED_SVG
        layout: CONSTRAINED
      )
    }
  }
}
`



export default IndexPage
