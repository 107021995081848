import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image";

import {logosReferences} from './logosReferences.module.scss'

const LogosReferences = () => {
  const data = useStaticQuery(graphql`{
  allFile(filter: {relativeDirectory: {eq: "references"}}) {
    edges {
      node {
        childImageSharp {
          gatsbyImageData(height: 50, transformOptions: {grayscale: true}, layout: FIXED)
        }
      }
    }
  }
}
`)

  var d = data && data.allFile && data.allFile.edges && data.allFile.edges.map(n => {
     return (
       <GatsbyImage
         image={n.node.childImageSharp.gatsbyImageData}
         key={n.node.childImageSharp.gatsbyImageData.src} />
     );
  })
    return <div className={logosReferences}>{d}</div>
  //return <Img fixed={data.placeholderImage.childImageSharp.fixed} />
}

export default LogosReferences
